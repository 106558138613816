import React, { useState } from 'react'
import Layout from 'components/Layout'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { Section, Row, Col } from 'components/grid'
import { Button } from '@arckit/ui'
import WebinarCard from 'components/events/webinars/WebinarCard'
import Modal from 'components/global/Modal'
import OverlayVideo from 'components/overlay-video'

export default function PreviousWebinarsPage() {
	const webinars = useStaticQuery<EventQuery>(query).allContentfulEvent.edges.filter((webinar) => new Date(webinar.node.date) < new Date())
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [recording, setRecording] = useState('')

	const handleClose = () => setIsModalOpen(false)

	return (
		<Layout>
			<Section flatGray>
				<Row className="pt-20">
					{webinars.map(({ node: event }) => (
						<WebinarCard event={event} previous setIsModalOpen={setIsModalOpen} setRecording={setRecording} />
					))}
				</Row>
			</Section>
			<Section>
				<Row>
					<Col className="flex justify-center">
						<Link to="/events/webinars">
							<Button>View Upcoming Webinars</Button>
						</Link>
					</Col>
				</Row>
			</Section>
			<Modal title={recording ? recording : ''} isOpen={isModalOpen} handleClose={handleClose} wide>
				<OverlayVideo title={recording} />
			</Modal>
		</Layout>
	)
}

export interface Event {
	title: string
	date: string
	description?: {
		description: string
	}
	shortDescription?: {
		shortDescription: string
	}
	registrationLink?: string
	presenters: {
		name: string
		title: string
		photo: {
			file: {
				url: string
			}
		}
		id: string
	}[]
	resources?: {
		resources: string
	}
	recording?: {
		title: string
		url: string
		thumbnail: {
			file: {
				url: string
			}
		}
	}
}

export interface EventQueryNode {
	node: Event
}
export interface EventQuery {
	allContentfulEvent: {
		edges: EventQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulEvent(sort: { fields: date, order: DESC }) {
			edges {
				node {
					title
					date
					description {
						description
					}
					shortDescription {
						shortDescription
					}
					registrationLink
					presenters {
						name
						title
						photo {
							file {
								url
							}
						}
						id
					}
					resources {
						resources
					}
					recording {
						title
						url
						thumbnail {
							file {
								url
							}
						}
					}
				}
			}
		}
	}
`
